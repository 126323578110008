<template>
    <div class="panel">
        <div class="panel-heading">
            {{ pageTitle }} Models
        </div>

        <div v-if="project.models.length > limit"
            class="panel-block">
            <div class="field is-grouped is-grouped-multiline">
                <search-input v-model="q"
                    placeholder="Search models"></search-input>
                <select-component v-model="application"
                    :choices="applications"
                    show-all-text="All applications"></select-component>
                <select-component v-model="discipline"
                    :choices="disciplines"
                    show-all-text="All disciplines"></select-component>
                <select-component v-if="versions.length > 1"
                    v-model="version"
                    :choices="versions"
                    show-all-text="All versions"></select-component>
                <div v-if="project.restrictionLevel == 'A'"
                    class="field is-grouped">
                    <label class="label py-2">Min. days since last audit upload</label>
                    <div class="control">
                        <input v-model="lastUpdate"
                            type="number"
                            class="input">
                    </div>
                </div>
                <clear-button v-if="isFiltered"
                    @click="clearFilters"></clear-button>
            </div>
        </div>

        <div class="panel-block is-block"
            v-for="model in filteredModels"
            :key="model.id">
            <span class="has-text-grey is-pulled-right"
                title="This is a cloud model">
                <span v-if="model.isCloudModel"
                    class="icon">
                    <span class="mdi mdi-cloud"></span>
                </span>
                <span>{{ model.version }}</span>
            </span>
            <router-link :to="{ name: 'model-detail', params: { id: model.id } }">
                {{ model.filename }}
            </router-link>
            <ul class="has-text-grey">
                <li :title="model.path">{{ model.location }}</li>
                <li v-if="model.discipline">
                    <span class="tag">{{ model.discipline }}</span>
                </li>
                <li v-if="model.lastModelInformationUpdate"
                    title="Last audit upload">
                    <span class="icon">
                        <span class="mdi mdi-cloud-upload"></span>
                    </span>
                    <span>
                        {{ model.lastModelInformationUpdate.fromNow() }}
                    </span>
                </li>
            </ul>
        </div>
        <div v-if="hasPrev || hasNext"
            class="panel-block">
            <pagination :has-prev="hasPrev"
                :has-next="hasNext"
                @pagechanged="onPageChange">
            </pagination>
        </div>
    </div>
</template>

<script>
import ClearButton from '@/core/components/ClearButton';
import Pagination from '@/core/components/Pagination';
import SearchInput from '@/core/components/SearchInput';
import SelectComponent from '@/core/components/SelectComponent';
import { uniq, uniqBy } from 'lodash';
import moment from 'moment';

export default {
    props: ['project'],
    components: {
        ClearButton,
        Pagination,
        SearchInput,
        SelectComponent,
    },
    data() {
        return {
            q: null,
            limit: 10,
            offset: 0,
            discipline: null,
            application: null,
            version: null,
            resultsCount: 0,
            lastUpdate: null,
        }
    },
    computed: {
        filteredModels() {
            let models = [...this.project.models];

            if (this.q)
                models = models.filter(x => x.filename.toLowerCase().includes(this.q.toLowerCase()));

            if (this.discipline)
                models = models.filter(x => x.discipline == this.discipline);

            if (this.application)
                models = models.filter(x => x.application == this.application);

            if (this.version)
                models = models.filter(x => x.version == this.version);

            if (this.lastUpdate) {
                let start = moment().subtract(this.lastUpdate, 'days');
                models = models.filter(x => x.lastModelInformationUpdate && (x.lastModelInformationUpdate < start));
            }

            this.resultsCount = models.length;

            return models.slice(this.offset, this.offset + this.limit);
        },
        hasNext() {
            return (this.offset + this.limit) < this.resultsCount;
        },
        hasPrev() {
            return this.offset > 0;
        },
        pageTitle() {
            let start = this.offset + 1;
            let end = this.offset + this.filteredModels.length;
            let total = this.resultsCount || 0;

            if (end > total)
                end = total;

            if (end == 0)
                return 'Showing 0';
            else if (total < this.limit)
                return `${total.toLocaleString()}`;
            else
                return `Showing ${start.toLocaleString()} to ${end.toLocaleString()} of ${total.toLocaleString()}`
        },
        disciplines() {
            return uniq(this.project.models.filter(x => x.discipline).map(x => x.discipline));
        },
        applications() {
            return uniqBy(this.project.models.filter(x => x.application).map(x => [x.application, x.applicationName]), x => x[0]);
        },
        versions() {
            return uniq(this.project.models.filter(x => x.version).map(x => x.version));
        },
        isFiltered() {
            return this.q || this.application || this.discipline || this.version || this.lastUpdate;
        },
    },
    methods: {
        onPageChange(e) {
            if (e == 'next')
                this.offset += this.limit;
            else if (e == 'previous')
                this.offset -= this.limit;
        },
        clearFilters() {
            this.q = null;
            this.discipline = null;
            this.version = null;
            this.lastUpdate = null;
            this.application = null;
        },
    },
    watch: {
        q(val) {
            if (val)
                this.offset = 0;
        },
        discipline(val) {
            if (val)
                this.offset = 0;
        },
    }
};
</script>
