<template>
    <div class="field">
        <label v-if="label"
            class="label">{{ label }}:</label>
        <div class="control">
            <div v-if="multiple">
                <div v-for="choice in entries">
                    <label class="checkbox">
                        <input type="checkbox"
                            :class="{ 'is-danger': error }"
                            v-model="selectedMultiple[choice[0]]">
                        {{ choice[1] }}
                    </label>
                </div>
            </div>
            <div v-else
                class="select"
                :class="{ 'is-danger': error }">
                <select v-model="selected">
                    <option v-if="showAll || showAllText"
                        :value="null">{{ showAllText || 'All choices' }}</option>
                    <option v-for="choice in entries"
                        :value="choice[0]">{{ choice[1] }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import { isArray, isObject } from 'lodash';

export default {
    props: {
        modelValue: Object,
        choices: [Array, Object],
        label: String,
        showAll: Boolean,
        showAllText: String,
        error: {
            default: false,
        },
        multiple: {
            default: false
        }
    },
    data() {
        return {
            selected: null,
            selectedMultiple: {},
        }
    },
    created() {
        if (this.multiple) {
            this.selectedMultiple = {};
            if (isArray(this.modelValue))
                this.modelValue.forEach(x => this.selectedMultiple[x] = true);
        }
        else {
            this.selected = this.modelValue;
        }
    },
    computed: {
        entries() {
            if (isArray(this.choices) && this.choices.length) {
                if (this.choices[0].length == 2)
                    return this.choices;
                else
                    return this.choices.map(x => [x, x]);
            } else if (isObject(this.choices)) {
                return Object.entries(this.choices);
            }
        },
    },
    watch: {
        selected() {
            this.$emit('update:modelValue', this.selected);
        },
        selectedMultiple: {
            handler(val) {
                if (val)
                    this.$emit('update:modelValue', Object.entries(val).filter(x => x[1]).map(x => x[0]));
                else
                    this.$emit('update:modelValue', null);
            },
            deep: true,
        },
        modelValue(val) {
            this.selected = val;
        }
    },
}
</script>