const parseSharedParameterFile = (text) => {
    let groupMap = {};
    let params = [];
    let groupMode = false;
    let paramMode = false;

    for (let line of text.split('\r\n')) {
        if (!line.trim()) {
            continue
        } else if (line.startsWith('#')) {
            continue;
        } else if (line.startsWith('*')) {
            groupMode = line.startsWith('*GROUP');
            paramMode = line.startsWith('*PARAM');
        } else {
            let bits = line.split('\t');
            if (groupMode)
                groupMap[bits[1]] = bits[2];
            else if (paramMode)
                params.push({
                    guid: bits[1],
                    name: bits[2],
                    dataType: bits[3],
                    dataCategory: bits[4],
                    group: groupMap[bits[5]],
                    visible: bits[6]
                })
        }
    }

    return params;
}

export {
    parseSharedParameterFile,
}